import { createStore } from "botframework-webchat";
import {
  EnableEndChatBtnIfNotExist,
  EnableEndChatBtnIfAlreadyExist,
  EnableEndChatHideLogic,
  DiasbleLoadingState,
} from '../EndChatButton';
import { getLocalewithUserTypeCheck } from  '../user';

const Extensions = ["pdf", "xml", "json", "png"];
const GUEST_SUPPORT_URL = process.env.REACT_APP_GUEST_WEB_URL;


export type storeStateUpdatetype = {
  setPlayMessageSound: any,
  setShowButtonFlag: any,
  setEndButtonFlag: any,
  setQueuePositionFromSF: any,
  setAgentQueuePosition: any,
  setQueueAttempt: any,
  setNotification: any,
  setWindowLocale: any,
  setCheck:any

}

export const getStore = ({ setPlayMessageSound, setShowButtonFlag, setEndButtonFlag,setQueuePositionFromSF, setAgentQueuePosition, setQueueAttempt,setNotification,setWindowLocale,setCheck }
  : storeStateUpdatetype) => {
  
 
  return createStore({}, ({ dispatch }: any) => (next: any) => (action: any) => {
    console.log("action.type", action.type,action.payload);

    if (action.type === "WEB_CHAT/SEND_MESSAGE") {
      if (
        action.payload.text.match(
          /(\d *){8,19}|(\d[-]*){8,19}|(\d[-]* *){8,19}|(\d\/* *){8,19}|(\d\\* *){8,19}|(\d\\*\/*[-]* *){8,19}/
        )
      ) {
        let num = action.payload.text.match(
          /(\d *){8,19}|(\d[-]*){8,19}|(\d[-]* *){8,19}|(\d\/* *){8,19}|(\d\\* *){8,19}|(\d\\*\/*[-]* *){8,19}/
        );
        let match =
          /^(?:3[47][0-9]{13}|(6541|6556)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})[0-9]{11}|9[0-9]{15}|(6304|6706|6709|6771)[0-9]{12,15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}|(62[0-9]{14,17})|4[0-9]{12}(?:[0-9]{3})?|(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}))$/;
        let rawStr = num[0].replace(/\s/g, "").replace(/-/g, "");
        let matched = rawStr.match(match);
        if (matched && matched.length > 0) {
          action.payload.text = action.payload.text.replace(
            num[0],
            "XXXX "
          );
        }
      }

      if (action.payload.text.length > 1024) {
        action.payload.text =
          "Sorry, I can not accept more that 1024 characters at a time.";
      }
    }

    if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {          
      const d1 = document.querySelector(
        ".webchat__basic-transcript__scrollable"
      );
      let d2 = document.createElement("div");
      d2.innerHTML = `<div class="bot-loading">Loading</div>`;
      d1?.appendChild(d2);

        let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
          "button[title='Upload file']"
      );

      if (fileUploadIconBtn)
      {
        fileUploadIconBtn.disabled = true;
        fileUploadIconBtn.parentElement!.style.width = '0px';
      }

      let fileUploadInput = document.querySelector<HTMLInputElement>(
        ".webchat__upload-button--file-input"
      );
      
      if (fileUploadInput !== null)
      {
        fileUploadInput?.removeAttribute("multiple");
        fileUploadInput.disabled = true;
        const fileTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "application/pdf",
        ];
        fileUploadInput.setAttribute("accept", fileTypes.join(","));

        fileUploadInput.addEventListener("change", (e) =>
        {
          var isLC = fileUploadInput?.getAttribute("data-isLiveChat");

          if (
            fileUploadInput?.value !== "" &&
            
            fileUploadInput!.files!.length > 0 &&
            fileUploadInput!.files![0].size > 4183818
          )
          {
            fileUploadInput!.value = "";
            dispatch({
              type: "WEB_CHAT/SEND_EVENT",
              payload: {
                name: isLC
                  ? "livechat/file_size_issue"
                  : "webchat/file_size_issue",
              },
            });
            e.preventDefault();
            return;
          }

          if (
            fileUploadInput!.value !== "" &&
            fileUploadInput!.files!.length > 0 &&
            !fileTypes.includes(fileUploadInput!.files![0]?.type)
          )
          {
            fileUploadInput!.value = "";
            dispatch({
              type: "WEB_CHAT/SEND_EVENT",
              payload: {
                name: isLC
                  ? "livechat/file_type_issue"
                  : "webchat/file_type_issue",
              },
            });
            e.preventDefault();
            return;
          }
        });
      }
    }

    if (
      action &&
      action.payload &&
      action.payload.activity &&
      action.payload.activity.type === "event" &&
      action.payload.activity.name === "WEB_CHAT/DISABLE_SUBMIT_BUTTON"
    ) {
      let submitButtonId = action.payload.activity.value;
  const submitBtn = document?.getElementById("#" + submitButtonId + " button") as HTMLButtonElement;
      if (submitBtn) {
        submitBtn?.setAttribute("disabled", "disabled");
        submitBtn!.style!.visibility = "hidden";
      }
    }

    if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          let lis = document.getElementsByClassName(
            "webchat__basic-transcript__activity"
          );
          let element = document.getElementsByClassName("bot-loading")?.[0];
          if (element && lis.length >= 1) {
            element.remove();
          }
          if (action.payload.activity.from.role === "bot") {
            const locale = getLocalewithUserTypeCheck();
            setWindowLocale(locale);
           
            if (
              action.payload.activity.name === "WEB_CHAT/ENABLE_ATTACHMENT_ICON"
            ) {
              const fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
                locale == "de"
                ? "button[title='Datei hochladen']"
                : "button[title='Upload file']"
              );
              const fileUploadInput = document.querySelector<HTMLInputElement>(
                ".webchat__upload-button--file-input"
              );

              fileUploadIconBtn!.disabled = false;
              fileUploadInput!.disabled = false;
              fileUploadIconBtn!.parentElement!.style.removeProperty("width");

              if (
                action.payload.activity.islivechat &&
                action.payload.activity.islivechat === "true"
              )
                fileUploadInput!.setAttribute("data-isLiveChat", "true");

              if (
                action.payload.activity.islivechat &&
                action.payload.activity.islivechat === "true"
              )
                fileUploadInput!.setAttribute("data-isLiveChat", "true");

              fileUploadIconBtn!.parentElement!.style.removeProperty("width");
            }
          }

          if (
            action.payload.activity.name === "WEB_CHAT/DISABLE_ATTACHMENT_ICON"
          ) {
            const locale = getLocalewithUserTypeCheck();
            let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
              locale == "de"
              ? "button[title='Datei hochladen']"
              : "button[title='Upload file']"
            );
            let fileUploadInput = document.querySelector<HTMLInputElement>(
              ".webchat__upload-button--file-input"
            );
            fileUploadIconBtn!.disabled = true;
            fileUploadInput!.disabled = true;
            fileUploadInput!.removeAttribute("data-isLiveChat");
            fileUploadIconBtn!.parentElement!.style.width = '0';
          }
          // Disbable submit form button
          if (action.payload.activity.name === "FORM_SUBMITTED") {
            const getElementButtonClass = document
              .getElementsByClassName(
                "webchat__basic-transcript__transcript"
              )?.[0]
              .querySelectorAll("button");
            for (let n = 0; n < getElementButtonClass.length; n++) {
              let buttonInnerText =
                getElementButtonClass[n].textContent ||
                getElementButtonClass[n].innerText;
              if (
                buttonInnerText === "Confirm" ||
                buttonInnerText === "Submit"
              ) {
                getElementButtonClass[n].disabled = true;
              }
            }
          }
          if (
            action.payload.activity.name === "WEB_CHAT/SHOW_CLOSE_CHAT_BUTTON"
          ) {
            console.log(
              "Event received from backend :",
              action.payload.activity
            );
            if (document.querySelector(".home-button")) {
              const homeEnableButton = document.querySelector(".home-button") as HTMLButtonElement;
              homeEnableButton.style.display = "none";
            }
            if (action.payload.activity.value) {
              const {qpos,uid } =JSON.parse(action.payload.activity.value);
              setQueuePositionFromSF({qpos,uid });
            }
            setAgentQueuePosition(true);

            if (!document.querySelector(".webChat_end-button")) {
              EnableEndChatBtnIfNotExist();
              let endChatBtn = document.querySelector(".webChat_end-button") as HTMLButtonElement;
              endChatBtn.addEventListener("click", (e) => {
                dispatch({
                  type: "WEB_CHAT/SEND_EVENT",
                  payload: {
                    value: "CLOSE_CHAT",
                    name: "CUSTOM_CHAT_EVENT",
                  },
                });
              });
            } else {
              EnableEndChatBtnIfAlreadyExist();
            }
            setCheck(true);
          }
          if (
            action.payload.activity.name === "WEB_CHAT/HIDE_CLOSE_CHAT_BUTTON"
          ) {
            setAgentQueuePosition(false);
            setQueuePositionFromSF(null);
            setQueueAttempt(0);
            console.log(
              "Event received from backend :",
              action.payload.activity
            );
            setEndButtonFlag(true);
            EnableEndChatHideLogic();
          }
          if (action.payload.activity.name === "WEB_CHAT/HIDE_LOAD_ICON") {
            DiasbleLoadingState();
          }
          if (
            action.payload.activity.name === "WEB_CHAT/DISABLE_SUBMIT_BUTTON"
          ) {
            const sysbDiv = document.querySelector(
              `${"#Survey_Submit_" + action.payload.activity.value}`
            );

            if (sysbDiv) {
              const fedBckMaindiv = sysbDiv.parentNode;
              if (fedBckMaindiv) {
                const comtxtArea = fedBckMaindiv.querySelector(
                  '[aria-label="Optional comments"]'
                ) as HTMLInputElement;
                if (comtxtArea) {
                  comtxtArea.readOnly = true;
                }

                const smileyArrays = fedBckMaindiv.querySelectorAll(
                  '[name="Survey_Satisfied"]'
                );
                if (smileyArrays && smileyArrays.length > 0) {
                  smileyArrays.forEach((item, index) => {
                   const smileyarray = smileyArrays[index] as HTMLInputElement
                   smileyarray.disabled = true;
                  });
                }
              }
            }
          }

          if (action.payload.activity.name === "WEB_CHAT/LIVE_CHAT_WEBFORM") {
          setShowButtonFlag(true);
          }
        }
        
    if (action.type === "DIRECT_LINE/CONNECT_REJECTED") {
      let element = document.getElementsByClassName(
          "webchat__basic-transcript__transcript"
      )?.[0];
      element.classList.remove("bot-loading");
    }

    if (action.type === "WEB_CHAT/SEND_POST_BACK") {
      if (action.payload.value.type === "webform") {
        setPlayMessageSound(true);
        
        let botLoading = document.createElement("span");
        botLoading.className = "bot-web-loading";

        let formSubmitButtons =
        document.querySelectorAll('[aria-label="Submit"]') ?? null;
      if (formSubmitButtons && formSubmitButtons.length > 0) {
        const lastSubmitButton = formSubmitButtons[
          formSubmitButtons.length - 1
        ] as HTMLButtonElement;
        lastSubmitButton.disabled = true;
        lastSubmitButton?.parentElement?.style.removeProperty("flex-direction");
        lastSubmitButton?.parentElement?.append(botLoading);
        }
        let formAbsendenButtons =
        document.querySelectorAll('[aria-label="Absenden"]') ?? null;
      if (formAbsendenButtons && formAbsendenButtons.length > 0) {
        const lastAnsendenButton = formAbsendenButtons[
          formAbsendenButtons.length - 1
        ] as HTMLButtonElement;
        lastAnsendenButton.disabled = true;
        lastAnsendenButton?.parentElement?.style.removeProperty("flex-direction");
        lastAnsendenButton?.parentElement?.append(botLoading);
      }
      
      }

      if (
        action.payload.value.type === "survey" ||
        action.payload.value.type === "Query"
      ) {
        const surveyButtons = document.querySelectorAll('[aria-label="Submit"]');

        if (surveyButtons && surveyButtons.length > 0) {
          surveyButtons.forEach((item, index) => {
          const surveyButton =surveyButtons[index] as HTMLButtonElement;
          surveyButton.disabled = true;
          });
        }
        const absendenButtons = document.querySelectorAll(
          '[aria-label="Absenden"]'
        );

        if (absendenButtons && absendenButtons.length > 0) {
          absendenButtons.forEach((item, index) => {
          const absendenButton =absendenButtons[index] as HTMLButtonElement;
          absendenButton.disabled = true;
          });
        }
      }
      if (action.payload.value.type === "Query") {
        const nextQueryButtons = document.querySelectorAll('[aria-label="Next"]');
        const weiterQueryButtons = document.querySelectorAll('[aria-label="Weiter"]');

        if (nextQueryButtons && nextQueryButtons.length > 0) {
          nextQueryButtons.forEach((item, index) => {
          const nextQueryButton =nextQueryButtons[index] as HTMLButtonElement;
          nextQueryButton.disabled = true;
          });
        }
        if (weiterQueryButtons && weiterQueryButtons.length > 0) {
          weiterQueryButtons.forEach((item, index) => {
          const weiterQueryButton =weiterQueryButtons[index] as HTMLButtonElement;
          weiterQueryButton.disabled = true;
          });
        }
      }
      if (action.payload.value.type === "guestemail") {
        window.open(
          GUEST_SUPPORT_URL,
          "Guest Ticket Creation",
          "width=1000,height=600"
        );
      }
    }
    if (action.type === "DIRECT_LINE/QUEUE_INCOMING_ACTIVITY") {
      if (
        action.payload.activity.from.role === "bot" &&
        action.payload.activity.inputHint === "acceptingInput"
      ) {
        console.log("play sound");

        setNotification(true);
      }
    }
      return next(action);
    }
  );
};

